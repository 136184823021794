/* eslint-disable no-unused-vars */
import {
  Add,
  ArrowBack,
  Delete,
  Edit,
  MoreVert,
  OpenInNew,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import deleteFeedTemplate from '../../../api/deleteFeedTemplate';
import deleteIntegration from '../../../api/deleteIntegration';
import CreateIntegrationDialog from './CreateIntegrationDialog';
import FeedEditor from './custom-feed/FeedEditor';

const dataApiBase =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'https://data.dh.gladior.com'
    : 'https://data-dev.dh.gladior.com';

async function getRequestToDataProviderApi(url) {
  const tokens = await Auth.currentSession();
  return API.get('DataProviderApi', url, {
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  }).then((response) => response.data);
}

export default function EditQuerySettingsDialog({
  close = () => {},
  setSettings = () => {},
  initialSettings = [],
  queryObj = {},
  update = () => {},
  refreshDataSource,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [settings, setSettingsObj] = React.useState(
    initialSettings.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.name]:
          // eslint-disable-next-line no-nested-ternary
          cur.value === 'true'
            ? true
            : cur.value === 'false'
              ? false
              : cur.value,
      }),
      {}
    )
  );

  const config = useSelector((state) => state.dataProviderSettings.config);
  const [showNewIntegrationDialog, setShowNewIntegrationDialog] =
    React.useState(false);
  const [showTemplateFeedDrawer, setShowTemplateFeedDrawer] = useState(false);
  const [selectedTemplateFeed, setSelectedTemplateFeed] = useState(null);
  const [prevAddFields, setPrevAddFields] = React.useState(0);

  async function deleteTemplateFeed(feedId) {
    await deleteFeedTemplate(feedId);
    await refreshDataSource();
  }

  React.useEffect(() => {
    const settingsKeys = Object.keys(settings).filter((key) => settings[key]);
    setSettings(
      settingsKeys.map((key) => ({
        name: key,
        value: `${settings[key]}`,
        __typename: 'DataField',
      }))
    );
  }, [settings]);

  React.useEffect(() => {
    if (
      prevAddFields !== 0 &&
      JSON.stringify(
        queryObj.additionalFields.map((aF) => ({
          name: aF.name,
          value: `${aF.value}`,
        }))
      ) !==
        JSON.stringify(
          prevAddFields.map((aF) => ({
            name: aF.name,
            value: `${aF.value}`,
          }))
        )
    ) {
      update();
    }
    setPrevAddFields(queryObj.additionalFields);
  }, [queryObj.additionalFields]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );
  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];
  const [selectedIntegration, setSelectedIntegration] = React.useState(
    // eslint-disable-next-line no-nested-ternary
    searchParams.get('dp')
      ? {
          additionalSettings: [],

          ...(queryObj?.integrations?.items?.find(
            (i) => i.id === searchParams.get('integrationId')
          ) ?? {}),
          dataProvider: searchParams.get('dp'),
          redirect: true,
        }
      : searchParams.get('integrationId')
        ? {
            ...(queryObj?.integrations?.items?.find(
              (i) => i.id === searchParams.get('integrationId')
            ) ?? {}),
          }
        : null
  );
  useEffect(() => {
    setSelectedIntegration(
      // eslint-disable-next-line no-nested-ternary
      searchParams.get('dp')
        ? {
            additionalSettings: [],

            ...(queryObj?.integrations?.items?.find(
              (i) => i.id === searchParams.get('integrationId')
            ) ?? {}),
            dataProvider: searchParams.get('dp'),
            redirect: true,
          }
        : searchParams.get('integrationId')
          ? {
              ...(queryObj?.integrations?.items?.find(
                (i) => i.id === searchParams.get('integrationId')
              ) ?? {}),
            }
          : null
    );
    if (searchParams.get('dp') || searchParams.get('integrationId')) {
      setShowNewIntegrationDialog(true);
    }
  }, []);
  useEffect(() => {
    setSearchParams((sp) => {
      sp.set('integrationId', selectedIntegration?.id);
      if (!selectedIntegration?.id) {
        sp.delete('integrationId');
      }
      sp.delete('dp');
      return sp;
    });
  }, [selectedIntegration]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        Power BI
        <Switch
          checked={settings.powerbi ?? false}
          color="info"
          onChange={(event) => {
            setSettingsObj((settingsObj) => ({
              ...settingsObj,
              powerbi: event.target.checked,
            }));
          }}
        />
      </Box>
      <br />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        Data URL
        <Switch
          checked={settings.data_uri ?? false}
          color="info"
          onChange={(event) => {
            setSettingsObj((settingsObj) => ({
              ...settingsObj,
              data_uri: event.target.checked,
            }));
          }}
        />{' '}
      </Box>
      {settings.data_uri && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignContent: 'center',
            flexWrap: 'wrap',
            gap: '10px',
            py: 2,
          }}
        >
          <Button
            color="info"
            href={`${dataApiBase}/d/${queryObj.id}?outputFormat=json`}
            startIcon={<OpenInNew />}
            sx={(theme) => ({
              textTransform: 'none',
              background: theme.palette.background.default,
              width: 'fit-content',
            })}
            target="_blank"
            variant="outlined"
          >
            <Typography variant="inherit">JSON</Typography>
          </Button>

          <Button
            color="info"
            href={`${dataApiBase}/d/${queryObj.id}?outputFormat=csv`}
            startIcon={<OpenInNew />}
            sx={(theme) => ({
              textTransform: 'none',
              background: theme.palette.background.default,
              width: 'fit-content',
            })}
            target="_blank"
            variant="outlined"
          >
            <Typography variant="inherit">CSV</Typography>
          </Button>
          <Button
            color="info"
            href={`${dataApiBase}/d/${queryObj.id}?outputFormat=xml`}
            startIcon={<OpenInNew />}
            sx={(theme) => ({
              textTransform: 'none',
              background: theme.palette.background.default,
              width: 'fit-content',
            })}
            target="_blank"
            variant="outlined"
          >
            <Typography variant="inherit">XML</Typography>
          </Button>
          <Button
            color="info"
            href={`${dataApiBase}/d/${queryObj.id}?outputFormat=powerbi`}
            startIcon={<OpenInNew />}
            sx={(theme) => ({
              textTransform: 'none',
              background: theme.palette.background.default,
              width: 'fit-content',
            })}
            target="_blank"
            variant="outlined"
          >
            <Typography variant="inherit">Power BI</Typography>
          </Button>
          {(queryObj?.feedTemplates?.items ?? []).map((ft) => (
            <Box
              key={ft.id}
              sx={(theme) => ({
                borderRadius: 1,
                overflow: 'hidden',
                border: `1px solid ${theme.palette.info.light}`,
                '&:hover': {
                  border: `1px solid ${theme.palette.info.dark}`,
                },
                display: 'flex',
                flexWrap: 'nowrap',
              })}
            >
              <Button
                color="info"
                href={`${dataApiBase}/f/${ft.id}`}
                startIcon={<OpenInNew />}
                sx={(theme) => ({
                  textTransform: 'none',
                  background: theme.palette.background.default,
                  width: 'fit-content',
                  borderRadius: 0,
                  border: '1px solid transparent',
                  '&:hover': {
                    border: '1px solid transparent',
                  },
                })}
                target="_blank"
                variant="outlined"
              >
                <Typography variant="inherit">{ft.name}</Typography>
              </Button>
              <Button
                color="info"
                endIcon={<Edit></Edit>}
                onClick={() => {
                  setShowTemplateFeedDrawer(true);
                  setSelectedTemplateFeed(ft);
                }}
                sx={(theme) => ({
                  textTransform: 'none',
                  background: theme.palette.background.default,
                  width: 'fit-content',
                  border: '1px solid transparent',
                  borderLeft: `1px solid ${theme.palette.divider}`,
                  '&:hover': {
                    border: '1px solid transparent',
                    borderLeft: `1px solid ${theme.palette.divider}`,
                  },
                  borderRadius: 0,
                })}
                variant="outlined"
              >
                Edit
              </Button>
              <Button
                sx={(theme) => ({
                  textTransform: 'none',
                  background: theme.palette.background.default,
                  width: 'fit-content',
                  border: '1px solid transparent',
                  borderLeft: `1px solid ${theme.palette.divider}`,
                  '&:hover': {
                    border: '1px solid transparent',
                    borderLeft: `1px solid ${theme.palette.divider}`,
                  },

                  borderRadius: 0,
                })}
                variant="outlined"
                onClick={async () => {
                  await deleteTemplateFeed(ft.id);
                }}
              >
                <Delete color="error"></Delete>
              </Button>
            </Box>
          ))}
          {(groups.includes('gladior') || groups.includes('Gladior_ATS')) && (
            <Button
              color="info"
              // href={`/${queryObj.id}/template-feed`}
              onClick={() => {
                setShowTemplateFeedDrawer(true);
              }}
              startIcon={<Add />}
              sx={(theme) => ({
                textTransform: 'none',
                background: theme.palette.background.default,
                width: 'fit-content',
              })}
              target="_blank"
              variant="outlined"
            >
              Add New
            </Button>
          )}
        </Box>
      )}
      {!settings.data_uri && <br></br>}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        Integrations
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Table>
          <TableBody>
            {(queryObj?.integrations?.items?.length ?? 0) === 0 && (
              <Typography
                sx={{
                  mt: 1,
                }}
                variant="body2"
              >
                No integrations found
              </Typography>
            )}
            {(queryObj?.integrations?.items ?? []).map((integration) => (
              <TableRow key={integration.id}>
                <TableCell>
                  <Box display="flex" flexDirection="row">
                    <Box sx={{ mr: 1 }}>
                      <Avatar sx={{ bgcolor: 'transparent' }} variant="square">
                        <img
                          alt={integration.dataProvider}
                          src={config[integration.dataProvider]?.image}
                          style={{
                            width: '100%',
                          }}
                        ></img>
                      </Avatar>
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        color="textPrimary"
                        component="span"
                        variant="body1"
                      >
                        {integration?.dataSource?.name}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        component="span"
                        variant="body2"
                      >
                        {ellipsis(
                          integration?.dataSource?.tags?.join(' > ') ?? '',
                          40
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Button
                    color="info"
                    onClick={async () => {
                      setSelectedIntegration(integration);
                      setShowNewIntegrationDialog(true);
                    }}
                    startIcon={<Edit />}
                    sx={(theme) => ({
                      textTransform: 'none',
                      background: theme.palette.background.default,
                      width: '100%',
                    })}
                    target="_blank"
                    variant="outlined"
                  >
                    <Typography
                      onClick={async () => {
                        setSelectedIntegration(integration);
                        setShowNewIntegrationDialog(true);
                      }}
                      variant="inherit"
                    >
                      Edit
                    </Typography>
                  </Button>
                </TableCell>
                <TableCell align="right" padding="none">
                  <PopupState variant="popover">
                    {(popupState) => (
                      <>
                        <IconButton
                          color="primary"
                          variant="contained"
                          {...bindTrigger(popupState)}
                          size="large"
                        >
                          <MoreVert></MoreVert>
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onClick={async () => {
                              setSelectedIntegration(integration);
                              setShowNewIntegrationDialog(true);
                              popupState.close();
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={async () => {
                              enqueueSnackbar(`Testing integration... `, {
                                variant: 'info',
                              });
                              popupState.close();
                              try {
                                await getRequestToDataProviderApi(
                                  `/data-hub/data?integrationId=${integration.id}&sendMessage=true`
                                );
                                enqueueSnackbar(
                                  `Success! View your message channel for the successful test`,
                                  {
                                    variant: 'success',
                                  }
                                );
                              } catch (e) {
                                enqueueSnackbar(`Failed to test integration`, {
                                  variant: 'error',
                                });
                              }
                            }}
                          >
                            Test
                          </MenuItem>
                          <MenuItem
                            onClick={async () => {
                              await deleteIntegration(integration.id);
                              popupState.close();
                              await refreshDataSource();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </PopupState>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      <CreateIntegrationDialog
        initialState={selectedIntegration}
        isOpen={showNewIntegrationDialog}
        onClose={async () => {
          setShowNewIntegrationDialog(false);
          setSelectedIntegration(null);
          setSearchParams((sp) => {
            sp.delete('dp');
            sp.delete('integrationId');
            return sp;
          });
          await refreshDataSource();
        }}
        queryObj={queryObj}
        setOpen={setShowNewIntegrationDialog}
        webhookUrl={`${dataApiBase}/wh/q/${queryObj.id}`}
      ></CreateIntegrationDialog>
      <Box
        sx={{
          py: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          color="primary"
          onClick={() => {
            close();
          }}
          startIcon={<ArrowBack />}
          variant="outlined"
        >
          Back
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setSelectedIntegration(null);
            setShowNewIntegrationDialog(true);
          }}
          startIcon={<Add />}
          variant="outlined"
        >
          Create New Integration
        </Button>
      </Box>
      <FeedEditor
        close={async () => {
          setShowTemplateFeedDrawer(false);
          setSelectedTemplateFeed(null);
          await refreshDataSource();
        }}
        initialState={selectedTemplateFeed}
        open={showTemplateFeedDrawer}
        queryObj={queryObj}
      ></FeedEditor>
    </>
  );
}
