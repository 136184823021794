import { Add, Cancel, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import FieldsAutocompleteSelector from '../../FieldsAutocompleteSelector';

const allowedMetricOperators = [
  'EQUALS',
  'NUMERIC_GREATER_THAN',
  'NUMERIC_GREATER_THAN_OR_EQUAL',
  'NUMERIC_LESS_THAN',
  'NUMERIC_LESS_THAN_OR_EQUAL',
];

const allowedDimensionOperators = [
  'EQUALS',
  'NUMERIC_GREATER_THAN',
  'NUMERIC_GREATER_THAN_OR_EQUAL',
  'NUMERIC_LESS_THAN',
  'NUMERIC_LESS_THAN_OR_EQUAL',
  'CONTAINS',
  'REGEXP_EXACT_MATCH',
  'REGEXP_PARTIAL_MATCH',
  'IN_LIST',
  'IS_NULL',
];

const noValueOperators = ['IS_NULL', 'IN_DATE_RANGE'];

const filterOperators = {
  EQUALS: 'Equal to (=)',
  CONTAINS: 'Contains',
  REGEXP_EXACT_MATCH: 'RegExp Match',
  REGEXP_PARTIAL_MATCH: 'RegExp Contains',
  IN_LIST: 'In',
  IS_NULL: 'Is Null',
  BETWEEN: 'Between (>= && <=)',
  NUMERIC_GREATER_THAN: 'Greater than (>)',
  NUMERIC_GREATER_THAN_OR_EQUAL: 'Greater than or equal to (>=)',
  NUMERIC_LESS_THAN: 'Less than (<)',
  NUMERIC_LESS_THAN_OR_EQUAL: 'Less than or equal to (<=)',
  IN_DATE_RANGE: 'In Date Range',
};

export function OrFilter({ addNew, remove, fields, setFilter, initialValue }) {
  const [includeExclude, setIncludeExclude] = useState('INCLUDE');
  const [field, setField] = useState(undefined);
  const [operator, setOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');
  useEffect(() => {
    setFilter({
      includeExclude,
      field,
      operator,
      filterValue,
    });
  }, [includeExclude, field, operator, filterValue]);

  useEffect(() => {
    if (initialValue) {
      setFilterValue(initialValue.filterValue);
      setOperator(initialValue.operator);
      setField(fields.find((f) => f.id === initialValue?.field?.id));
      setIncludeExclude(initialValue.includeExclude);
    }
  }, [JSON.stringify(initialValue)]);
  return (
    <>
      <TableRow
        sx={(theme) => ({
          background: '#F8F9FA',
          border: `1px solid ${theme.palette.divider}`,
        })}
      >
        <TableCell
          sx={{
            p: 0,
          }}
        >
          <Select
            defaultValue={'INCLUDE'}
            onChange={(event) => setIncludeExclude(event.target.value)}
            sx={{
              borderRadius: '0px',
              width: '100%',
              minWidth: '100px',
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: '1px solid black',
              },
            }}
            value={includeExclude}
          >
            <MenuItem value={'INCLUDE'}>Include</MenuItem>
            <MenuItem value={'EXCLUDE'}>Exclude</MenuItem>
          </Select>
        </TableCell>
        <TableCell
          sx={{
            p: 0,
          }}
        >
          <FieldsAutocompleteSelector
            disableClearable={true}
            fieldOptions={fields}
            multiple={true}
            placeholder="Select a field"
            setValue={(value) => {
              setFilterValue('');
              const newField = value.at(-1);
              setField(newField);
              if (
                newField?.conceptType === 'METRIC' &&
                (!operator || !allowedMetricOperators.includes(operator))
              ) {
                setOperator(allowedMetricOperators[0]);
              }
              if (
                newField?.conceptType === 'DIMENSION' &&
                (!operator || !allowedDimensionOperators.includes(operator))
              ) {
                setOperator(allowedDimensionOperators[0]);
              }
            }}
            sx={{
              width: '100%',
              minWidth: '150px',
              outline: 'none',
              border: 'none',

              '& fieldset': {
                border: 'none',
                borderRadius: '0px',
              },
              '&:hover fieldset': {
                border: '1px solid black',
              },
              ' .MuiInputBase-root': { flexWrap: 'nowrap', height: '3.5em' },
            }}
            value={[field].filter((v) => v)}
          ></FieldsAutocompleteSelector>
        </TableCell>
        <TableCell
          sx={{
            p: 0,
          }}
        >
          <Select
            disabled={!field}
            onChange={(event) => {
              setOperator(event.target.value);
              if (noValueOperators.includes(event.target.value)) {
                setFilterValue('');
              }
            }}
            sx={{
              borderRadius: '0px',
              width: '100%',
              minWidth: '100px',
              '& fieldset': { border: 'none' },
              '&:hover fieldset': {
                border: '1px solid black',
              },
            }}
            value={operator}
          >
            {field?.conceptType === 'METRIC' &&
              allowedMetricOperators.map((key) => (
                <MenuItem key={key} value={key}>
                  {filterOperators[key]}
                </MenuItem>
              ))}
            {field?.conceptType === 'DIMENSION' &&
              allowedDimensionOperators.map((key) => (
                <MenuItem key={key} value={key}>
                  {filterOperators[key]}
                </MenuItem>
              ))}
          </Select>
        </TableCell>

        <TableCell
          sx={{
            p: 0,
          }}
        >
          <TextField
            disabled={
              !operator || noValueOperators.includes(operator) || !field
            }
            onChange={(event) => setFilterValue(event.target.value)}
            sx={{
              '> .MuiInputBase-root': {
                borderRadius: '0px',
              },
              '& fieldset': { border: 'none' },
              '&:hover fieldset': {
                border: '1px solid black',
              },
              width: '100%',
              minWidth: '150px',
            }}
            value={filterValue}
          ></TextField>
        </TableCell>
        <TableCell
          sx={{
            px: 1,
            py: 0,
            width: 0,
          }}
        >
          <Box
            sx={{
              height: '4em',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button color="info" onClick={addNew}>
              OR
            </Button>
          </Box>
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            width: 0,
          }}
        >
          <Box
            sx={{
              height: '4em',
              display: 'flex',
              alignItems: 'center',
              pr: 1,
            }}
          >
            <IconButton onClick={remove}>
              <Cancel></Cancel>
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

export function AndFilter({ isLast, remove, fields, setFilter, initialValue }) {
  const [filters, setFilters] = useState([
    {
      id: Math.random().toString(36).substring(7),
    },
  ]);

  useEffect(() => {
    if (initialValue) {
      setFilter(initialValue);
    }
  }, [JSON.stringify(initialValue)]);

  return (
    <>
      <Table
        sx={{
          mb: 1,
        }}
      >
        <TableBody>
          {filters.map((or, i) => (
            <OrFilter
              addNew={() => {
                const id = (Math.random() + 1).toString(36).substring(7);
                setFilters([...filters, { id }]);
              }}
              fields={fields}
              initialValue={initialValue?.[i] || {}}
              key={or.id}
              remove={() => {
                if (filters.length === 1) {
                  remove();
                }
                setFilters(filters.filter((_, index) => index !== i));
              }}
              setFilter={(filter) => {
                const newFilters = [...filters];
                newFilters[i] = { ...filter, id: or.id };
                setFilters(newFilters);
                setFilter(newFilters);
              }}
            />
          ))}
        </TableBody>
      </Table>

      {!isLast && (
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            height: '40px',
            '& .MuiDivider-wrapper': {
              py: 0,
            },
            mb: 1,
            alignContent: 'flex-end',
            width: 'fit-content',
          }}
        >
          <Typography
            color="textSecondary"
            sx={{
              width: '100%',
              textAlign: 'right',
            }}
            variant="caption"
          >
            AND
          </Typography>
        </Divider>
      )}
    </>
  );
}

export default function FilterBox({
  fields,
  onChange = () => {},
  initialValue = [],
}) {
  const [filters, setFilters] = useState([]);
  const [initialFilters, setInitialFilters] = useState([]);
  function addAndFilter() {
    setFilters([
      ...filters,
      [{ andId: Math.random().toString(36).substring(7) }],
    ]);
  }

  useEffect(() => {
    const transformedFilters = filters.map((filter) =>
      filter.map((f) => ({
        operator: f.operator,
        type: f.includeExclude,
        fieldName: f?.field?.id,
        values: f?.filterValue?.split(', '),
      }))
    );
    onChange(transformedFilters);
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    const transformedFilters = initialValue.map((filter) => {
      const andId = Math.random().toString(36).substring(7);
      return filter.map((f) => ({
        operator: f.operator,
        includeExclude: f.type,
        field: fields.find((field) => field.id === f.fieldName),
        filterValue: f?.values?.join(', '),
        andId,
        id: Math.random().toString(36).substring(7),
      }));
    });
    setFilters(transformedFilters);
    setInitialFilters(JSON.parse(JSON.stringify(transformedFilters)));
  }, [JSON.stringify(initialValue)]);

  return (
    <Box sx={{ overflow: 'hidden', width: '100%' }}>
      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={2}
          >
            <Typography>Condition</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {filters.map((filter, index) => (
            <AndFilter
              fields={fields}
              initialValue={initialFilters[index]}
              isLast={index === filters.length - 1}
              key={filter?.[0]?.andId}
              remove={() => {
                setFilters([...filters.filter((_, i) => i !== index)]);
              }}
              setFilter={(f) => {
                const newFilters = [...filters];
                newFilters[index] = [
                  ...f.map((newFilter) => ({
                    ...newFilter,
                    andId: filter?.[0]?.andId,
                  })),
                ];

                setFilters(newFilters);
              }}
            ></AndFilter>
          ))}
          <Box>
            <Box
              alignItems={'center'}
              display="flex"
              flexDirection={'column'}
              justifyContent="center"
            >
              <Button
                color="info"
                onClick={() => {
                  addAndFilter();
                }}
                startIcon={<Add />}
                variant="outlined"
              >
                Add a condition
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
