/* eslint-disable no-case-declarations */
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import _filter from 'lodash/filter';

import { AdaptiveCard } from 'adaptivecards-react';
import { API, Auth } from 'aws-amplify';

import { useSnackbar } from 'notistack';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { batch, useDispatch, useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import updateDataSourceObject from '../../api/updateDataSourceObject';
import schedule from '../../config/schedule';
import teams from '../../config/teams';
import * as types from '../../constants/ActionTypes';
import AddCredentialButton from './AddCredentialButton';
import QueryComposer from './QueryComposer';

async function postRequestToDataProviderApi(url, { arg }) {
  const tokens = await Auth.currentSession();
  return API.post('DataProviderApi', url, {
    body: arg,
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  }).then((response) => response.data);
}

function CreateNotificationActionDialog({ open, setOpen, initialState }) {
  const reduxDispatch = useDispatch();

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);

  const { items: credentials } = credentialsByTeam[selectedUserTeam] || {
    items: [],
  };

  const filteredCredentials = _filter(credentials, (o) =>
    o.dataProvider.includes('MICROSOFT_TEAMS')
  );

  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = React.useReducer((oldState, action) => {
    switch (action.type) {
      case 'SET_DATE_RANGE':
        return { ...oldState, dateRange: action.value };

      case 'SET_START_DATE':
        return { ...oldState, startDate: action.value };
      case 'SET_END_DATE':
        return { ...oldState, endDate: action.value };
      case 'SET_SELECTED_METRICS':
        return { ...oldState, selectedMetrics: action.value };
      case 'SET_SORT_FIELD':
        return { ...oldState, sortField: action.value };
      case 'SET_LIMIT':
        return { ...oldState, limit: action.value };
      case 'SET_SELECTED_DIMENSIONS':
        return { ...oldState, selectedDimensions: action.value };
      case 'SET_DATE_RANGE_ERROR':
        return { ...oldState, dateRangeError: action.value };
      case 'SET_DATA_URL_NAME':
        return { ...oldState, name: action.value };
      case 'SET_CREDENTIAL_ID':
        return { ...oldState, notificationActionCredentialId: action.value };
      case 'SET_SCHEDULE':
        return { ...oldState, schedule: action.value };
      case 'SET_TRIGGER':
        return { ...oldState, trigger: action.value };
      case 'SET_DIMENSION_FILTER_PROP':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters = [...oldState.dimensionsFilters];
        newDimensionFilters[action.andFilterIndex][action.orFilterIndex][
          action.property
        ] = action.value;
        return { ...oldState, dimensionsFilters: newDimensionFilters };
      case 'REMOVE_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters2 = [];
        oldState.dimensionsFilters.forEach((dFilter, andIndex) => {
          const dIFs = [];
          dFilter.forEach((dIF, orIndex) => {
            if (
              action.andFilterIndex !== andIndex ||
              action.orFilterIndex !== orIndex
            ) {
              dIFs.push(dIF);
            }
          });
          if (dIFs.length > 0) {
            newDimensionFilters2.push(dIFs);
          }
        });
        return { ...oldState, dimensionsFilters: newDimensionFilters2 };
      case 'ADD_OR_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters3 = [];
        oldState.dimensionsFilters.forEach((dFilter, andIndex) => {
          const dIFs = [];
          dFilter.forEach((dIF) => {
            dIFs.push(dIF);
          });

          if (action.andFilterIndex === andIndex) {
            dIFs.push({
              field: null,
              values: [],
              type: 'INCLUDE',
              operator: 'none',
            });
          }

          newDimensionFilters3.push(dIFs);
        });
        return { ...oldState, dimensionsFilters: newDimensionFilters3 };
      case 'ADD_AND_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters4 = [...oldState.dimensionsFilters];

        newDimensionFilters4.push([
          {
            field: null,
            values: [],
            type: 'INCLUDE',
            operator: 'none',
          },
        ]);

        return { ...oldState, dimensionsFilters: newDimensionFilters4 };
      case 'RESET_STATE':
        return initialState;

      case 'INITIALIZE_FIELDS':
        // eslint-disable-next-line no-case-declarations
        const { fieldIds, sort, dimensionsFiltersObjects, ...otherStateProps } =
          oldState;
        return {
          ...otherStateProps,
          selectedMetrics: action.selectedMetrics,
          selectedDimensions: action.selectedDimensions,
          dimensionsFilters: action.dimensionsFilters,
          sortField: action.sortField,
          startDate: action.startDate,
          endDate: action.endDate,
        };

      default:
        throw new Error();
    }
  }, initialState);

  const { enqueueSnackbar } = useSnackbar();

  const [runError, setRunError] = React.useState('');

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource
  );
  const dataProviderSettings = useSelector(
    (reduxState) => reduxState.dataProviderSettings
  );
  const configSettings =
    dataProviderSettings.config[selectedDataSource?.dataProvider];

  const {
    trigger: triggerPreview,
    isMutating: previewIsMutating,
    data: previewData,
    error: previewError,
    reset: resetPreviewData,
  } = useSWRMutation(
    '/data-providers/adaptive-cards/preview',
    postRequestToDataProviderApi
  );

  const { trigger, isMutating } = useSWRMutation(
    '/data-providers/notification-action',
    postRequestToDataProviderApi
  );

  if (!selectedDataSource) {
    return <></>;
  }

  async function runData() {
    let isValid = true;

    if (
      state.dateRange === 'CUSTOM' &&
      (!state.startDate || !state.endDate) &&
      configSettings.dateRangeRequired
    ) {
      isValid = false;
      setRunError(
        'For the Date Range "CUSTOM", the start and end date field is mandatory'
      );
    }

    if (
      state.selectedMetrics.length === 0 &&
      state.selectedDimensions.length === 0
    ) {
      isValid = false;
      setRunError('At least one field (dimension or metric) is required');
    }

    if (!state.dateRange && configSettings.dateRangeRequired) {
      isValid = false;
      setRunError('Please choose a "Date Range"');
    }

    if (isValid) {
      setRunError('');

      triggerPreview({
        teamsCredentialId: state.notificationActionCredentialId,
        dataSourceId: selectedDataSource.id,
        dateRangeType: state.dateRange,
        startDate: state.startDate,
        endDate: state.endDate,
        name: state.name,
        template: state.template,
        limit: state.limit,
        dimensionsFilters: state.dimensionsFilters.map((andFilter) =>
          andFilter.map((orFilter) => ({
            ...orFilter,
            fieldName: orFilter.field.id,
          }))
        ),
        fields: [...state.selectedDimensions, ...state.selectedMetrics].map(
          (fieldObj) => fieldObj.id
        ),
        sort: state.sortField
          ? [
              {
                id: state.sortField.id,
                sortDirection: state.sortField.sortDirection,
              },
            ]
          : [],
      });
    }
  }

  function closeExplorer() {
    resetPreviewData();
    dispatch({
      type: 'RESET_STATE',
      value: '',
    });
    setOpen(false);
  }

  async function createOrUpdateNotificationAction() {
    let isValid = true;

    if (
      state.dateRange === 'CUSTOM' &&
      (!state.startDate || !state.endDate) &&
      configSettings.dateRangeRequired
    ) {
      isValid = false;

      enqueueSnackbar(
        'For the Date Range "CUSTOM", the start and end date field is mandatory',
        {
          variant: 'error',
        }
      );
    }

    if (
      state.selectedMetrics.length === 0 &&
      state.selectedDimensions.length === 0
    ) {
      isValid = false;

      enqueueSnackbar('At least one field (dimension or metric) is required', {
        variant: 'error',
      });
    }

    if (
      !state.notificationActionCredentialId ||
      state.notificationActionCredentialId === ''
    ) {
      isValid = false;

      enqueueSnackbar('Select webhook', {
        variant: 'error',
      });
    }

    if (!state.dateRange && configSettings.dateRangeRequired) {
      isValid = false;

      enqueueSnackbar('Please choose a "Date Range"', {
        variant: 'error',
      });
    }

    if (isValid) {
      await trigger({
        id: state.id || '',
        dataSourceId: selectedDataSource.id,
        name: state.name
          ? state.name
          : `Microsoft Teams integration ${
              selectedDataSource.notificationActions.items.length + 1
            }`,
        fields: [...state.selectedDimensions, ...state.selectedMetrics].map(
          (fieldObj) => fieldObj.id
        ),
        sort: state.sortField
          ? [
              {
                id: state.sortField.id,
                sortDirection: state.sortField.sortDirection,
              },
            ]
          : [],
        dateRange: {
          startDate: state.dateRange === 'CUSTOM' ? state.startDate : '',
          endDate: state.dateRange === 'CUSTOM' ? state.endDate : '',
        },
        dateRangeType: state.dateRange,
        notificationActionCredentialId: state.notificationActionCredentialId,
        template: state.template,
        notificationService: state.notificationService,
        status: state.status,
        schedule: state.schedule,
        trigger: state.trigger,
        limit: state.limit || '',
        dimensionsFilters: state.dimensionsFilters.map((andFilter) =>
          andFilter.map((orFilter) => ({
            ...orFilter,
            fieldName: orFilter.field.id,
          }))
        ),
      });

      const updatedDataSource = await updateDataSourceObject({
        id: selectedDataSource.id,
      });

      batch(() => {
        reduxDispatch({
          type: types.UPDATE_TEAM_DATA_SOURCE,
          dataSource: updatedDataSource,
          teamId: updatedDataSource.team.id,
        });
      });

      setTimeout(() => {
        closeExplorer();
      }, 500);
    }
  }

  return (
    <Dialog
      maxWidth={'lg'}
      onClose={() => {
        closeExplorer();
      }}
      open={open}
    >
      <AppBar
        color="default"
        elevation={0}
        position="static"
        sx={(theme) => ({
          bgcolor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.action.disabled}`,
        })}
      >
        <Toolbar variant="regular">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="100%"
          >
            <Typography align="center" color="inherit" variant="h6">
              Create Microsoft Teams integration
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <DialogContent sx={{ minWidth: '600px' }}>
        <Typography
          color="textSecondary"
          gutterBottom
          sx={{ mb: 2 }}
          variant="subtitle2"
        >
          Step 1: Choose or create a Microsoft Teams Webhook
        </Typography>
        <FormControl fullWidth size="small" sx={{ mb: 2 }}>
          <InputLabel>Microsoft Teams webhook</InputLabel>
          <Select
            label="Microsoft Teams webhook"
            onChange={(event) => {
              dispatch({
                type: 'SET_CREDENTIAL_ID',
                value: event.target.value,
              });
            }}
            value={state.notificationActionCredentialId}
          >
            {filteredCredentials.map((credential) => (
              <MenuItem key={credential.id} value={credential.id}>
                {credential.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <AddCredentialButton
          selectedDataProvider={'MICROSOFT_TEAMS'}
          variant="credentialPicker"
        />

        <Box sx={{ my: 2 }}>
          <Divider flexItem orientation="horizontal" />
        </Box>

        <Typography
          color="textSecondary"
          gutterBottom
          sx={{ mb: 1 }}
          variant="subtitle2"
        >
          Step 2: Title of the Microsoft Teams Integration
        </Typography>

        <TextField
          fullWidth
          label={'Name'}
          margin="dense"
          onChange={(event) => {
            dispatch({
              type: 'SET_DATA_URL_NAME',
              value: event.target.value,
            });
          }}
          placeholder="Microsoft Teams integration 1"
          size="small"
          value={state.name}
          variant="outlined"
        />
        <Box sx={{ my: 2 }}>
          <Divider flexItem orientation="horizontal" />
        </Box>

        <Typography
          color="textSecondary"
          gutterBottom
          sx={{ mb: 2 }}
          variant="subtitle2"
        >
          Step 3: Integration settings
        </Typography>
        <Box>
          <QueryComposer
            dataProvider={selectedDataSource?.dataProvider}
            dataSourceId={selectedDataSource?.id}
            dispatch={dispatch}
            state={state}
          />
          <FormHelperText error>{state.dateRangeError}</FormHelperText>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography
            color="textSecondary"
            gutterBottom
            sx={{ mb: 2 }}
            variant="subtitle2"
          >
            Step 4: Microsoft Teams Integration settings
          </Typography>

          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth size="small">
              <InputLabel>Trigger</InputLabel>
              <Select
                label="Trigger"
                onChange={(event) => {
                  dispatch({
                    type: 'SET_TRIGGER',
                    value: event.target.value,
                  });
                }}
                value={state.trigger}
              >
                <MenuItem value={'SCHEDULE'}>Schedule</MenuItem>
                <MenuItem value={'WEBHOOK'}>Data Hub Webhook</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {state.trigger === 'SCHEDULE' && (
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth size="small">
                <InputLabel>Schedule</InputLabel>
                <Select
                  label="Schedule"
                  onChange={(event) => {
                    dispatch({
                      type: 'SET_SCHEDULE',
                      value: event.target.value,
                    });
                  }}
                  value={state.schedule}
                >
                  {Object.keys(schedule).map((scheduleKey) => (
                    <MenuItem key={scheduleKey} value={scheduleKey}>
                      {schedule[scheduleKey]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            py: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignContent: 'center',
          }}
        >
          <Button
            color="info"
            onClick={() => {
              runData();
            }}
            variant="outlined"
          >
            Preview
          </Button>
        </Box>

        {runError && <Alert severity="error">{runError}</Alert>}

        {previewIsMutating && (
          <Box
            sx={{
              minHeight: '300px',
              mt: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!previewIsMutating && previewError && (
          <Alert severity="error">
            {previewError?.response?.data?.message}
          </Alert>
        )}

        {!previewError && !previewIsMutating && previewData && (
          <Box
            sx={(theme) => ({
              bgcolor: theme.palette.background.default,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              p: 2,
              py: 4,
              border: `1px solid ${theme.palette.action.disabled}`,
            })}
          >
            <AdaptiveCard
              hostConfig={teams}
              payload={previewData}
              style={{ width: '500px', border: '1px solid black' }}
            />
          </Box>
        )}

        {false && (
          <Box sx={{ maxHeight: 300, maxWidth: 400 }}>
            <Typography variant="caption">
              <pre>{JSON.stringify(state, null, 2)}</pre>
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.action.disabled}`,
          py: 2,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              closeExplorer();
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            color="info"
            loading={isMutating}
            onClick={createOrUpdateNotificationAction}
            variant="contained"
          >
            {state.id ? 'Update' : 'Create'}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default CreateNotificationActionDialog;
