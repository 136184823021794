/* eslint-disable no-console */

import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import filterGraphQLFields from '../utilities/filterGraphQLFields';

async function checkIfUserExists(username) {
  try {
    const excludeArray = ['teams'];

    const updateDataSourceFilter = (path) => {
      const re = new RegExp(`getUser.(${excludeArray.join('|')})$`);
      return !re.test(path);
    };

    const user = await API.graphql(
      graphqlOperation(
        filterGraphQLFields(queries.getUser, updateDataSourceFilter),
        { id: username }
      )
    );

    const { getUser } = user.data;

    if (getUser) {
      return getUser;
    }
  } catch (err) {
    console.error(`error fetching user: ${username}`, err);
  }
  return undefined;
}

async function getUserObject() {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

  // not sure why this if statement is needed
  // based on https://github.com/aws-samples/aws-appsync-chat/blob/master/src/mobx/UserStore.js
  if (currentAuthenticatedUser?.username !== '') {
    const userObject = await checkIfUserExists(
      currentAuthenticatedUser?.username
    );
    if (userObject) {
      return { userObject, currentAuthenticatedUser };
    }
  }

  // renew session and try again

  // eslint-disable-next-line no-async-promise-executor
  await new Promise(async (resolve, reject) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, () => {
        resolve();
      });
    } catch (e) {
      reject(e);
    }
  });

  const currentAuthenticatedUserRetry = await Auth.currentAuthenticatedUser();

  // not sure why this if statement is needed
  // based on https://github.com/aws-samples/aws-appsync-chat/blob/master/src/mobx/UserStore.js
  if (currentAuthenticatedUserRetry?.attributes?.sub !== '') {
    const userObject = await checkIfUserExists(
      currentAuthenticatedUserRetry?.attributes?.sub
    );

    return {
      userObject,
      currentAuthenticatedUser: currentAuthenticatedUserRetry,
    };
  }

  return undefined;
}

export default getUserObject;
