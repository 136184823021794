/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import { Add, Cancel, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  alpha,
} from '@mui/material';
import { blue, green } from '@mui/material/colors';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _find from 'lodash/find';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import dateRangeLabels from '../../config/dateRangeLabels';
import * as dateRange from '../../constants/DateRange';
import useDataProviderData from '../../hooks/useDataProviderData';
import AutocompleteDatePicker from './AutocompleteDatePicker';
import FieldsAutocompleteSelector from './FieldsAutocompleteSelector';

dayjs.extend(relativeTime);

const testRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}/;

const DIVIDER = 'DIVIDER';

const dateRangesKeys = [
  dateRange.CUSTOM,
  DIVIDER,
  dateRange.LAST_7_DAYS,
  dateRange.LAST_14_DAYS,
  dateRange.LAST_28_DAYS,
  dateRange.LAST_30_DAYS,
  DIVIDER,
  dateRange.TODAY,
  dateRange.YESTERDAY,
  DIVIDER,
  dateRange.THIS_WEEK_STARTS_SUNDAY,
  dateRange.THIS_WEEK_STARTS_SUNDAY_TO_DATE,
  dateRange.LAST_WEEK_STARTS_SUNDAY,
  dateRange.THIS_WEEK_STARTS_MONDAY,
  dateRange.THIS_WEEK_STARTS_MONDAY_TO_DATE,
  dateRange.LAST_WEEK_STARTS_MONDAY,
  DIVIDER,
  dateRange.THIS_MONTH,
  dateRange.THIS_MONTH_TO_DATE,
  dateRange.LAST_MONTH,
  dateRange.THIS_QUARTER,
  dateRange.THIS_QUARTER_TO_DATE,
  dateRange.LAST_QUARTER,
  dateRange.THIS_YEAR,
  dateRange.THIS_YEAR_TO_DATE,
  dateRange.LAST_YEAR,
];

const filterTypes = {
  INCLUDE: 'Include',
  EXCLUDE: 'Exclude',
};

const allowedMetricOperators = [
  'EQUALS',
  'NUMERIC_GREATER_THAN',
  'NUMERIC_GREATER_THAN_OR_EQUAL',
  'NUMERIC_LESS_THAN',
  'NUMERIC_LESS_THAN_OR_EQUAL',
];

const allowedDimensionOperators = [
  'EQUALS',
  'NUMERIC_GREATER_THAN',
  'NUMERIC_GREATER_THAN_OR_EQUAL',
  'NUMERIC_LESS_THAN',
  'NUMERIC_LESS_THAN_OR_EQUAL',
  'CONTAINS',
  'REGEXP_EXACT_MATCH',
  'REGEXP_PARTIAL_MATCH',
  'IN_LIST',
  'IS_NULL',
];

const noValueOperators = ['IS_NULL', 'IN_DATE_RANGE'];

const filterOperators = {
  EQUALS: 'Equal to (=)',
  CONTAINS: 'Contains',
  REGEXP_EXACT_MATCH: 'RegExp Match',
  REGEXP_PARTIAL_MATCH: 'RegExp Contains',
  IN_LIST: 'In',
  IS_NULL: 'Is Null',
  BETWEEN: 'Between (>= && <=)',
  NUMERIC_GREATER_THAN: 'Greater than (>)',
  NUMERIC_GREATER_THAN_OR_EQUAL: 'Greater than or equal to (>=)',
  NUMERIC_LESS_THAN: 'Less than (<)',
  NUMERIC_LESS_THAN_OR_EQUAL: 'Less than or equal to (<=)',
  IN_DATE_RANGE: 'In Date Range',
};

// eslint-disable-next-line no-unused-vars
function FilterRow({
  fields,
  state,
  dispatch,
  orEnabled,
  andFilterIndex,
  orFilterIndex,
  filterCount,
}) {
  const foundField =
    state.dimensionsFilters[andFilterIndex][orFilterIndex].field;

  const { operator } = state.dimensionsFilters[andFilterIndex][orFilterIndex];
  const { values } = state.dimensionsFilters[andFilterIndex][orFilterIndex];

  const filterType =
    state.dimensionsFilters[andFilterIndex][orFilterIndex].type;

  let allowedOperators;

  if (foundField?.conceptType === 'METRIC') {
    allowedOperators = allowedMetricOperators;
  } else {
    allowedOperators = allowedDimensionOperators;
  }

  if (
    foundField?.type?.startsWith('YEAR_MONTH') &&
    !allowedOperators.includes('IN_DATE_RANGE')
  ) {
    allowedOperators.push('IN_DATE_RANGE');
  }
  return (
    <TableRow>
      <PopupState variant="popover">
        {(popupState) => (
          <>
            <TableCell
              sx={(theme) => ({
                p: 2,
                cursor: 'pointer',
                '&:hover': {
                  border: `1px solid ${theme.palette.primary.main}`,
                  boxShadow: theme.shadows[6],
                },
              })}
              {...bindTrigger(popupState)}
            >
              <Typography variant="body2">{filterTypes[filterType]}</Typography>
            </TableCell>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Card>
                <CardContent>
                  <Select
                    onChange={(event) => {
                      dispatch({
                        type: 'SET_DIMENSION_FILTER_PROP',
                        property: 'type',
                        andFilterIndex,
                        orFilterIndex,
                        value: event.target.value,
                      });
                    }}
                    size="small"
                    value={filterType}
                  >
                    {Object.keys(filterTypes).map((typeKey) => (
                      <MenuItem key={typeKey} value={typeKey}>
                        {filterTypes[typeKey]}
                      </MenuItem>
                    ))}
                  </Select>
                </CardContent>
              </Card>
            </Popover>
          </>
        )}
      </PopupState>

      <PopupState variant="popover">
        {(popupState) => (
          <>
            <TableCell
              sx={(theme) => ({
                p: 2,
                cursor: 'pointer',

                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',

                '&:hover': {
                  border: `1px solid ${theme.palette.primary.main}`,
                  boxShadow: theme.shadows[6],
                },
              })}
              {...bindTrigger(popupState)}
            >
              <>
                {foundField ? (
                  <Chip
                    label={foundField?.name}
                    size="small"
                    sx={{
                      bgcolor:
                        foundField?.conceptType === 'DIMENSION'
                          ? green[100]
                          : blue[100],
                      cursor: 'pointer',
                    }}
                    variant="filled"
                  />
                ) : (
                  <Typography
                    color={foundField ? 'textPrimary' : 'text.disabled'}
                    noWrap
                    variant="body2"
                  >
                    Select a field
                  </Typography>
                )}
              </>
            </TableCell>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Card>
                <CardContent sx={{ minWidth: '300px' }}>
                  <FieldsAutocompleteSelector
                    disableClearable={true}
                    fieldOptions={fields}
                    isLoading={false}
                    label={'Field'}
                    multiple={false}
                    setValue={(selectedFields) => {
                      dispatch({
                        type: 'SET_DIMENSION_FILTER_PROP',
                        property: 'field',
                        andFilterIndex,
                        orFilterIndex,
                        value: selectedFields,
                      });
                    }}
                    value={foundField || null}
                  />
                </CardContent>
              </Card>
            </Popover>
          </>
        )}
      </PopupState>

      <PopupState variant="popover">
        {(popupState) => (
          <>
            {foundField ? (
              <TableCell
                sx={(theme) => ({
                  p: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    border: `1px solid ${theme.palette.primary.main}`,
                    boxShadow: theme.shadows[6],
                  },
                })}
                {...bindTrigger(popupState)}
              >
                <Typography
                  color={
                    filterOperators?.[operator]
                      ? 'textPrimary'
                      : 'text.disabled'
                  }
                  noWrap
                  variant="body2"
                >
                  {filterOperators?.[operator] || 'Select a condition'}
                </Typography>
              </TableCell>
            ) : (
              <TableCell></TableCell>
            )}
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Card>
                <CardContent sx={{ minWidth: '200px' }}>
                  <Select
                    fullWidth
                    onChange={(event) => {
                      dispatch({
                        type: 'SET_DIMENSION_FILTER_PROP',
                        property: 'operator',
                        andFilterIndex,
                        orFilterIndex,
                        value: event.target.value,
                      });
                    }}
                    size="small"
                    value={operator}
                  >
                    {operator === 'none' && (
                      <MenuItem disabled value={'none'}>
                        <Typography color={'text.disabled'} variant="inherit">
                          Select a condition
                        </Typography>
                      </MenuItem>
                    )}
                    {allowedOperators.map((operatorKey) => (
                      <MenuItem key={operatorKey} value={operatorKey}>
                        {filterOperators[operatorKey]}
                      </MenuItem>
                    ))}
                  </Select>
                </CardContent>
              </Card>
            </Popover>
          </>
        )}
      </PopupState>
      <PopupState variant="popover">
        {(popupState) => (
          <>
            {filterOperators?.[operator] &&
            !noValueOperators.includes(operator) ? (
              <TableCell
                sx={(theme) => ({
                  p: 2,
                  cursor: 'pointer',

                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',

                  '&:hover': {
                    border: `1px solid ${theme.palette.primary.main}`,
                    boxShadow: theme.shadows[6],
                  },
                })}
                {...bindTrigger(popupState)}
              >
                <Typography
                  color={values.length > 0 ? 'textPrimary' : 'text.disabled'}
                  noWrap
                  variant="body2"
                >
                  {values.length > 0 ? values.join(',') : 'example: value'}
                </Typography>
              </TableCell>
            ) : (
              <TableCell></TableCell>
            )}
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Card>
                <CardContent>
                  <TextField
                    fullWidth
                    margin="dense"
                    onChange={(event) => {
                      dispatch({
                        type: 'SET_DIMENSION_FILTER_PROP',
                        property: 'values',
                        andFilterIndex,
                        orFilterIndex,
                        value: event.target.value.split(','),
                      });
                    }}
                    placeholder="example: value"
                    size="small"
                    value={values.join(',')}
                    variant="outlined"
                  />
                </CardContent>
              </Card>
            </Popover>
          </>
        )}
      </PopupState>

      <TableCell
        sx={() => ({
          p: 2,
          marginLeft: 'auto !important',
          flexDirection: 'row',
        })}
      >
        <Stack
          alignItems={'center'}
          direction="row"
          justifyContent="flex-end"
          spacing={2}
        >
          <Typography variant="body2">
            <Button
              color="info"
              disabled={
                !orEnabled ||
                !foundField ||
                !filterType ||
                !filterOperators?.[operator]
              }
              onClick={() => {
                dispatch({
                  type: 'ADD_OR_DIMENSION_FILTER',
                  andFilterIndex,
                });
              }}
              size="small"
            >
              OR
            </Button>
          </Typography>

          <IconButton
            onClick={() => {
              dispatch({
                type: 'REMOVE_DIMENSION_FILTER',
                andFilterIndex,
                orFilterIndex,
              });
            }}
            size="small"
          >
            <Cancel fontSize="small" />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

// eslint-disable-next-line no-unused-vars
function FiltersRow({ fields, filters, state, dispatch, andFilterIndex }) {
  return (
    <TableContainer>
      <Table
        sx={(theme) => ({
          p: 0,
          minWidth: 650,
          bgcolor: theme.palette.background.default,
          border: `1px solid ${alpha(theme.palette.action.disabled, 0.12)}`,
        })}
      >
        <TableBody>
          {filters.map((filter, index) => (
            <FilterRow
              andFilterIndex={andFilterIndex}
              dispatch={dispatch}
              fields={fields}
              filter={filter}
              filterCount={filters.length}
              key={`filter${index}`}
              orEnabled={filters.length - 1 === index}
              orFilterIndex={index}
              state={state}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function QueryComposer({ dataProvider, dataSourceId, state, dispatch }) {
  const [inputValueStartDate, setInputValueStartDate] = React.useState('');
  const [inputValueEndDate, setInputValueEndDate] = React.useState('');
  const [sortOptions, setSortOptions] = React.useState([]);

  const dataProviderSettings = useSelector(
    (reduxState) => reduxState.dataProviderSettings
  );

  const configSettings = dataProviderSettings.config[dataProvider];
  const { data = [], isLoading } = useDataProviderData(
    configSettings && `/${configSettings.route}/fields/`,
    {
      dataSourceId,
    }
  );

  const metrics = data.filter((fieldObj) => fieldObj.conceptType === 'METRIC');
  const dimensions = data.filter(
    (fieldObj) => fieldObj.conceptType === 'DIMENSION'
  );

  React.useEffect(() => {
    let startDateDayJs;
    let endDateDayJs;

    if (inputValueStartDate) {
      const isADate = testRegex.test(inputValueStartDate);
      if (isADate) {
        startDateDayJs = dayjs(inputValueStartDate);
      } else if (inputValueStartDate === 'today') {
        startDateDayJs = dayjs();
      } else if (inputValueStartDate === 'yesterday') {
        startDateDayJs = dayjs().subtract(1, 'day');
      }
    }

    if (inputValueEndDate) {
      const isADate = testRegex.test(inputValueEndDate);
      if (isADate) {
        endDateDayJs = dayjs(inputValueEndDate);
      } else if (inputValueEndDate === 'today') {
        endDateDayJs = dayjs();
      } else if (inputValueEndDate === 'yesterday') {
        endDateDayJs = dayjs().subtract(1, 'day');
      }
    }

    if (startDateDayJs && endDateDayJs) {
      if (startDateDayJs.isAfter(endDateDayJs)) {
        dispatch({
          type: 'SET_DATE_RANGE_ERROR',
          value: 'Start date is after End date',
        });
      } else {
        dispatch({
          type: 'SET_DATE_RANGE_ERROR',
          value: '',
        });
      }
    }
  }, [inputValueStartDate, inputValueEndDate]);

  React.useEffect(() => {
    const allSelectedFields = [...metrics, ...state.selectedDimensions];

    const allSortOptions = [];

    allSelectedFields.forEach((fieldObj) => {
      allSortOptions.push({
        ...fieldObj,
        name: `${fieldObj.name} (desc)`,
        sortDirection: 'DESC',
      });
      allSortOptions.push({
        ...fieldObj,
        name: `${fieldObj.name} (asc)`,
        sortDirection: 'ASC',
      });
    });

    setSortOptions(allSortOptions);
  }, [state.selectedMetrics, state.selectedDimensions]);

  React.useEffect(() => {
    if (
      state &&
      state.fieldIds &&
      state.fieldIds.length > 0 &&
      data &&
      data.length > 0
    ) {
      const tempMetrics = [];
      const tempDimensions = [];
      const tempDimensionsFilters = [];
      let sortField = null;
      state.fieldIds.forEach((fieldId) => {
        const foundField = _find(data, {
          id: fieldId,
        });

        if (foundField?.conceptType === 'DIMENSION') {
          tempDimensions.push(foundField);
        } else {
          tempMetrics.push(foundField);
        }
      });

      if (state.dimensionsFiltersObjects) {
        state.dimensionsFiltersObjects.forEach((dfObject) => {
          const andFilters = [];
          dfObject.orFilters.forEach((filterObject) => {
            const foundField = _find(data, {
              id: filterObject.fieldName,
            });
            andFilters.push({
              field: foundField,
              values: filterObject.values,
              type: filterObject.type,
              operator: filterObject.operator,
            });
          });

          tempDimensionsFilters.push(andFilters);
        });
      }

      if (state.sort && state.sort.length > 0) {
        const foundField = _find(data, {
          id: state.sort[0].id,
        });

        sortField = {
          ...foundField,
          name: `${
            foundField.name
          } (${state.sort[0].sortDirection.toLowerCase()})`,
          sortDirection: state.sort[0].sortDirection,
        };
      }

      if (state.startDate && !inputValueStartDate) {
        setInputValueStartDate(state.startDate);
      }

      if (state.endDate && !inputValueEndDate) {
        setInputValueEndDate(state.endDate);
      }

      const startDate = state.startDate || inputValueStartDate || '';
      const endDate = state.endDate || inputValueEndDate || '';
      dispatch({
        type: 'INITIALIZE_FIELDS',
        selectedMetrics: tempMetrics,
        selectedDimensions: tempDimensions,
        dimensionsFilters: tempDimensionsFilters,
        sortField,
        startDate,
        endDate,
      });
    }
  }, [state.fieldIds, data]);

  React.useEffect(() => {
    if (state) {
      if (state.startDate && !inputValueStartDate) {
        setInputValueStartDate(state.startDate);
      }

      if (state.endDate && !inputValueEndDate) {
        setInputValueEndDate(state.endDate);
      }
    }
  }, [state]);

  return (
    <Stack direction={'column'} spacing={2}>
      <Box sx={{ mt: 2 }}>
        <Stack direction={'column'} spacing={2}>
          <Box>
            <FieldsAutocompleteSelector
              fieldOptions={dimensions}
              isLoading={isLoading}
              label={'Dimensions'}
              setValue={(selectedFields) => {
                dispatch({
                  type: 'SET_SELECTED_DIMENSIONS',
                  value: selectedFields,
                });
              }}
              value={state.selectedDimensions}
            />
          </Box>
          <Box>
            <FieldsAutocompleteSelector
              fieldOptions={metrics}
              isLoading={isLoading}
              label={'Metrics'}
              setValue={(selectedFields) => {
                dispatch({
                  type: 'SET_SELECTED_METRICS',
                  value: selectedFields,
                });
              }}
              value={state.selectedMetrics}
            />
          </Box>
        </Stack>
      </Box>

      {configSettings?.dateRangeRequired && (
        <Box>
          <Stack direction={'column'} spacing={1}>
            <Box>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel
                  sx={(theme) => ({
                    bgcolor: theme.palette.background.paper,
                  })}
                >
                  Date Range
                </InputLabel>
                <Select
                  onChange={(event) => {
                    dispatch({
                      type: 'SET_DATE_RANGE',
                      value: event.target.value,
                    });
                  }}
                  value={state.dateRange}
                >
                  {dateRangesKeys.map((rangeKey, i) => {
                    if (rangeKey === DIVIDER) {
                      return <Divider key={`DIVIDER-${i}`} />;
                    }
                    return (
                      <MenuItem key={rangeKey} value={rangeKey}>
                        {dateRangeLabels[rangeKey]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            {state.dateRange === 'CUSTOM' && (
              <Box>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 1 }}
                >
                  <AutocompleteDatePicker
                    inputValue={inputValueStartDate}
                    label={'Start date'}
                    setInputValue={(inputValue) => {
                      setInputValueStartDate(inputValue);
                      dispatch({
                        type: 'SET_START_DATE',
                        value: inputValue,
                      });
                    }}
                  />

                  <AutocompleteDatePicker
                    inputValue={inputValueEndDate}
                    label={'End date'}
                    setInputValue={(inputValue) => {
                      setInputValueEndDate(inputValue);
                      dispatch({
                        type: 'SET_END_DATE',
                        value: inputValue,
                      });
                    }}
                  />
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>
      )}

      {sortOptions.length > 0 && (
        <Box>
          <FieldsAutocompleteSelector
            fieldOptions={sortOptions}
            isLoading={isLoading}
            label={'Sort'}
            multiple={false}
            setValue={(selectedFields) => {
              dispatch({
                type: 'SET_SORT_FIELD',
                value: selectedFields,
              });
            }}
            value={state.sortField}
          />
        </Box>
      )}

      <Box>
        <TextField
          fullWidth
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: 0,
            max: 50,
          }}
          label="Limit"
          onChange={(e) => {
            dispatch({
              type: 'SET_LIMIT',
              value: e.target.value,
            });
          }}
          size="small"
          type="number"
          value={state.limit}
        />
      </Box>

      <Box>
        <Accordion variant="outlined">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              spacing={2}
            >
              <Typography>Filters</Typography>
              <Typography color="textSecondary" variant="caption">
                (Optional)
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction={'column'}
              divider={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{
                      height: '40px',
                      '& .MuiDivider-wrapper': {
                        py: 0,
                      },
                    }}
                  >
                    <Typography color="textSecondary" variant="caption">
                      AND
                    </Typography>
                  </Divider>
                </Box>
              }
              spacing={2}
            >
              {state.dimensionsFilters.map((filters, index) => (
                <FiltersRow
                  andFilterIndex={index}
                  dispatch={dispatch}
                  fields={[...dimensions, ...metrics]}
                  filters={filters}
                  key={`filter${index}`}
                  state={state}
                />
              ))}
            </Stack>
            {state.dimensionsFilters.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  my: 2,
                  ml: 1,
                }}
              >
                <Button
                  color="info"
                  onClick={() => {
                    dispatch({
                      type: 'ADD_AND_DIMENSION_FILTER',
                    });
                  }}
                  size="small"
                  sx={{ minWidth: 0 }}
                >
                  AND
                </Button>
              </Box>
            )}

            {state.dimensionsFilters.length === 0 && (
              <Box>
                <Box
                  alignItems={'center'}
                  display="flex"
                  flexDirection={'column'}
                  justifyContent="center"
                >
                  <Button
                    color="info"
                    onClick={() => {
                      dispatch({
                        type: 'ADD_AND_DIMENSION_FILTER',
                      });
                    }}
                    startIcon={<Add />}
                    variant="outlined"
                  >
                    Add a filter
                  </Button>
                </Box>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Stack>
  );
}

export default QueryComposer;
