/* eslint-disable react/jsx-boolean-value */

import { ContactSupportOutlined, Paid } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Button,
  Hidden,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import FuzzySearch from 'fuzzy-search';
import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useSelector } from 'react-redux';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import getAllCredentials from '../api/getAllCredentials';
import getAllDataSources from '../api/getAllDataSources';
import getAllLabels from '../api/getAllLabels';

import logoDev from '../data-hub-icon-dev.svg';
import logo from '../logo-icon.svg';
import SearchSuggestion from './SearchSuggestion';
import TeamInviteMenu from './TeamInviteMenu';
import CredentialLabel from './credentials/CredentialLabel';
import DataProviderChip from './data/DataProviderChip';
import DataSourceLabel from './labels/DataSourceLabel';
import TeamsMenu from './teams/TeamsMenu';
import UserProfileMenu from './user/UserProfileMenu';

const REPORT = 'REPORT';
const DATA_SOURCE = 'DATA_SOURCE';
const LABEL = 'LABEL';
const CREDENTIAL = 'CREDENTIAL';
const DATA_PROVIDER = 'DATA_PROVIDER';

const SUGGESTION = 'SUGGESTION';
const QUERY_LABEL = 'QUERY_LABEL';
const MAX_SUGGESTION_RESULTS = 5;

const useStyles = makeStyles((theme) => ({
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    // borderBottom: `1px solid ${alpha(theme.palette.action.disabled, 0.12)}`,
    // background: theme.palette.background.paper,
    // transition: theme.transitions.create(['margin', 'width'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    padding: '0 2px',

    boxShadow: 'none',
    borderStyle: 'solid',
    borderColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[700]
        : theme.palette.grey[200],
    borderWidth: 0,
    borderBottomWidth: 'thin',
    background:
      theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#FFF',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[500]
        : theme.palette.grey[800],
    // '& .MuiIconButton-root': {
    //   border: `1px solid ${
    //     theme.palette.mode === 'dark'
    //       ? theme.palette.grey[600]
    //       : theme.palette.grey[200]
    //   }`,
    //   borderRadius: theme.shape.borderRadius,
    //   color:
    //     theme.palette.mode === 'dark' ? '#FFF' : theme.palette.primary[500],
    //   background:
    //     theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#FFF',
    // },
  },
  searchWhite: {
    flexGrow: 2,
    flexShrink: 1,

    position: 'relative',
    border: `1px solid ${
      theme.palette.mode === 'dark'
        ? theme.palette.grey[600]
        : theme.palette.grey[200]
    }`,
    borderRadius: theme.shape.borderRadius,
    // border: `1px solid ${alpha(theme.palette.action.disabled, 0.12)}`,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[50]
        : theme.palette.grey[50],
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.grey[700]
          : theme.palette.grey[100],
    },
    color: theme.palette.mode === 'dark' ? 'white' : theme.palette.grey[900],
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },

    transitionProperty: 'all',
    transitionDuration: '150ms',
  },

  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  adornedStart: {
    paddingLeft: theme.spacing(10),
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
}));

function NavigationBar() {
  const classes = useStyles();

  const theme = useTheme();

  const textFieldRef = React.useRef();

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const q = searchParams.get('q') ? searchParams.get('q') : '';
  const labels = searchParams.get('labels') ? searchParams.get('labels') : '';

  const connectors = searchParams.get('connectors')
    ? searchParams.get('connectors')
    : '';
  const credentials = searchParams.get('credentials')
    ? searchParams.get('credentials')
    : '';

  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  // redux selectors
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  // local state
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedCredential, setSelectedCredential] = useState(null);

  const [searchQuery, setSearchQuery] = useState(q || '');

  let dataProviders = Object.keys(dataProviderSettings.config).map((key) => {
    const prov = dataProviderSettings.config[key];
    return { id: key, name: prov.name, image: prov.image };
  });

  dataProviders = dataProviders.filter((el) => el !== null);

  useEffect(() => {
    async function fetchCredential() {
      const { credentials: newCredentials } = await getAllCredentials(
        selectedUserTeam,
        undefined,
        1000,
        {
          or: [
            {
              id: {
                contains: credentials, // TODO: replace contains with equals, not working for some reason
              },
            },
          ],
        }
      );

      if (newCredentials && newCredentials.length === 1) {
        setSelectedCredential(newCredentials[0]);
      }
    }

    if (credentials) {
      fetchCredential();
    } else {
      setSelectedCredential(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  useEffect(() => {
    async function fetchLabel() {
      const { dataSourceLabels: newDataSourceLabels } = await getAllLabels(
        selectedUserTeam,
        undefined,
        1000,
        {
          or: [
            {
              id: {
                contains: labels, // TODO: replace contains with equals, not working for some reason
              },
            },
          ],
        }
      );

      if (newDataSourceLabels && newDataSourceLabels.length === 1) {
        setSelectedLabel(newDataSourceLabels[0]);
      }
    }

    if (labels) {
      fetchLabel();
    } else {
      setSelectedLabel(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  const [stateSuggestions, setSuggestions] = useState([]);

  function renderInputComponent(inputProps) {
    const { ref, ...other } = inputProps;
    const newOther = { ...other, type: 'search' };

    return (
      <InputBase
        {...inputProps}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
          adornedStart: classes.adornedStart,
        }}
        placeholder="Searching..."
        startAdornment={
          <Box display="flex" flexDirection="row" mr={1}>
            {selectedCredential && (
              <CredentialLabel
                alwaysShowDeleteButton
                confirmDeletion={false}
                dataProvider={selectedCredential.dataProvider[0]}
                deletable
                deleteAction={async () => {
                  searchParams.delete('credentials');
                  setSearchParams(searchParams);
                }}
                email={selectedCredential.email}
                key={selectedCredential.id}
                name={selectedCredential.name}
                picture={selectedCredential.picture}
                type={selectedCredential.dataProvider[0]}
                variant="SEARCH_BAR"
              />
            )}

            {connectors && dataProviderSettings.config[connectors] && (
              <Box mr={1}>
                <DataProviderChip
                  dataProvider={connectors}
                  deletable
                  deleteAction={async () => {
                    const queryObj = querystring.parse(
                      location.search.slice(1)
                    );
                    delete queryObj.connectors;
                    navigate({
                      ...location,
                      search: querystring.stringify(queryObj),
                    });
                  }}
                  variant="SEARCH_BAR"
                />
              </Box>
            )}
            {selectedLabel && (
              <DataSourceLabel
                alwaysShowDeleteButton
                confirmDeletion={false}
                deletable
                deleteAction={async () => {
                  searchParams.delete('labels');
                  setSearchParams(searchParams);
                }}
                key={selectedLabel.id}
                name={selectedLabel.name}
                type={selectedLabel.type}
                variant="SEARCH_BAR"
              />
            )}
          </Box>
        }
        {...newOther}
        inputRef={textFieldRef}
      />
    );
  }

  const onSuggestionsFetchRequested = async ({ value }) => {
    const [
      { dataSources },
      { dataSourceLabels: newDataSourceLabels },
      { credentials: credentials2 },
    ] = await Promise.all([
      getAllDataSources(selectedUserTeam, undefined, 100, {
        or: [
          { nameLowercase: { contains: value.toLowerCase() } },
          { dataProvider: { contains: value.toUpperCase() } },
        ],
      }),
      getAllLabels(selectedUserTeam, undefined, 10, {
        or: [
          {
            nameLowercase: {
              contains: value.trim().toLowerCase(),
            },
          },
        ],
      }),
      getAllCredentials(selectedUserTeam, undefined, 100, {
        or: [
          {
            email: {
              contains: value.trim(),
            },
          },
        ],
      }),
    ]);

    const fuse = new FuzzySearch(dataProviders, ['name'], {
      includeScore: false,
      caseSensitive: false,
    });

    const result = fuse.search(value);

    const newSuggestions = [
      { name: value, type: QUERY_LABEL },

      ...result.map((o) => ({
        ...o,
        name: o.name,
        type: SUGGESTION,
        variant: DATA_PROVIDER,
      })),

      ...newDataSourceLabels.slice(0, 2).map((o) => ({
        ...o,
        type: SUGGESTION,
        variant: LABEL,
      })),
      ...dataSources.slice(0, MAX_SUGGESTION_RESULTS).map((o) => ({
        ...o,
        name: o.displayName ? o.displayName : o.name,
        type: SUGGESTION,
        variant: DATA_SOURCE,
      })),
      ...credentials2.slice(0, 2).map((o) => ({
        ...o,
        type: SUGGESTION,
        variant: CREDENTIAL,
      })),
    ];
    console.log(dataSources);
    setSuggestions(newSuggestions);
  };

  return (
    <AppBar className={classes.appBar} elevation={0} position="sticky">
      <Paper elevation={0} square={true}>
        <Toolbar>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box
              display="flex"
              flexBasis="0%"
              flexDirection="row"
              flexGrow="1"
              flexShrink="1"
            >
              <Box
                alignContent="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Link to="/workspace">
                  <Avatar
                    src={
                      process.env.REACT_APP_USER_BRANCH === 'prod'
                        ? logo
                        : logoDev
                    }
                    variant="rounded"
                  />
                </Link>
              </Box>
              <Hidden xlDown>
                <Box
                  alignItems="center"
                  display="flex"
                  height="100%"
                  margin="0px 0px 0px 10px"
                >
                  <Typography variant="subtitle1">
                    {process.env.REACT_APP_USER_BRANCH === 'prod' ? (
                      'Data Hub'
                    ) : (
                      <Typography noWrap variant="inherit">
                        Data Hub{' '}
                        <Typography color="textSecondary" variant="caption">
                          (dev)
                        </Typography>
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </Hidden>
              <Box margin="0px 20px">
                <Divider orientation="vertical" />
              </Box>
              <TeamsMenu />
            </Box>

            <Box
              display="flex"
              flexBasis="0%"
              flexDirection="row"
              flexGrow="2"
              flexShrink="1"
            >
              <Box
                className={classes.searchWhite}
                style={{
                  backgroundColor: '#f1f3f4',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box className={classes.searchIcon}>
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.grey[500]
                          : theme.palette.primary[500],
                    }}
                  />
                </Box>

                <Box width="100%">
                  <Autosuggest
                    getSuggestionValue={(suggestion) => suggestion.name}
                    highlightFirstSuggestion
                    inputProps={{
                      id: 'react-autosuggest-popper',
                      label: 'Report',
                      placeholder: 'Search...',
                      value: searchQuery,
                      onChange: (event, { newValue }) => {
                        if (newValue === '') {
                          searchParams.delete('q');
                          setSearchParams(searchParams);
                        }

                        setSearchQuery(newValue);
                      },

                      onKeyDown: (event) => {
                        if (event.keyCode === 13) {
                          event.preventDefault();
                          setSuggestions([]);
                        } else if (event.keyCode === 8) {
                          if (searchQuery.length === 0 && selectedLabel) {
                            searchParams.delete('labels');
                            setSearchParams(searchParams);
                          }

                          if (
                            searchQuery.length === 0 &&
                            !selectedLabel &&
                            connectors &&
                            dataProviderSettings.config[connectors]
                          ) {
                            searchParams.delete('connectors');
                            setSearchParams(searchParams);
                          }

                          if (
                            searchQuery.length === 0 &&
                            !selectedLabel &&
                            !connectors &&
                            selectedCredential
                          ) {
                            searchParams.delete('credentials');
                            setSearchParams(searchParams);
                          }
                        }
                      },
                    }}
                    onSuggestionSelected={(event, { suggestion }) => {
                      if (suggestion.type !== QUERY_LABEL) {
                        setSearchQuery('');
                      }

                      if (suggestion.type === QUERY_LABEL) {
                        navigate({
                          ...location,
                          search: `q=${searchQuery}`,
                        });
                        setSearchQuery(searchQuery);
                      } else if (suggestion.variant === LABEL) {
                        navigate({
                          ...location,
                          search: `labels=${suggestion.id}`,
                        });
                        setSearchQuery('');
                      } else if (suggestion.variant === CREDENTIAL) {
                        navigate({
                          ...location,
                          search: `credentials=${suggestion.id}`,
                        });
                        setSearchQuery('');
                      } else if (suggestion.variant === DATA_PROVIDER) {
                        navigate({
                          ...location,
                          search: `connectors=${suggestion.id}`,
                        });
                        setSearchQuery('');
                      } else if (suggestion.variant === REPORT) {
                        navigate(
                          `/workspace/${
                            selectedUserTeam.split('-')[0]
                          }/reports/r/${suggestion.id}`
                        );
                      } else if (suggestion.variant === DATA_SOURCE) {
                        navigate(
                          `/workspace/${
                            selectedUserTeam.split('-')[0]
                          }/data-sources/d/${suggestion.id}`
                        );
                      }
                    }}
                    onSuggestionsClearRequested={() => {
                      setSuggestions([]);
                    }}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    renderInputComponent={renderInputComponent}
                    renderSuggestion={(
                      suggestion,
                      { query: renderQuery, isHighlighted }
                    ) => (
                      <SearchSuggestion
                        isHighlighted={isHighlighted}
                        query={renderQuery}
                        suggestion={suggestion}
                      />
                    )}
                    renderSuggestionsContainer={(options) => (
                      <Popper
                        anchorEl={textFieldRef.current}
                        open={Boolean(options.children)}
                      >
                        <Box mt={2} padding="0px 20px">
                          <Paper
                            square
                            {...options.containerProps}
                            style={{
                              width: textFieldRef.current
                                ? textFieldRef.current.offsetWidth
                                : 0,
                            }}
                          >
                            {options.children}
                          </Paper>
                        </Box>
                      </Popper>
                    )}
                    suggestions={stateSuggestions}
                    theme={{
                      suggestionsList: classes.suggestionsList,
                      suggestion: classes.suggestion,
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              alignItems="center"
              display="flex"
              flexBasis="0%"
              flexDirection="row"
              flexGrow="1"
              flexShrink="1"
              justifyContent="flex-end"
            >
              {!groups.includes('Gladior_ATS') && (
                <Button
                  color="secondary"
                  href="https://www.paypal.com/donate/?hosted_button_id=SVTYRAM54D2H8"
                  startIcon={<Paid></Paid>}
                  sx={{ borderRadius: '50px' }}
                  variant="contained"
                >
                  Donate
                </Button>
              )}
              <Box ml={1} mr={1}>
                <TeamInviteMenu />
              </Box>
              <Box mr={2}>
                <Tooltip title="Get support">
                  <IconButton
                    color="info"
                    href="https://datahub.gladior.com/support"
                    rel="noopener"
                    target="_blank"
                  >
                    <ContactSupportOutlined />
                  </IconButton>
                </Tooltip>
              </Box>
              <UserProfileMenu />
            </Box>
          </Box>
        </Toolbar>
      </Paper>
    </AppBar>
  );
}

export default NavigationBar;
