/* eslint-disable no-nested-ternary */

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import useSWRMutation from 'swr/mutation';

import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/ActionTypes';
import QueryComposer from './QueryComposer';

dayjs.extend(relativeTime);

async function sendRequest(url, { arg }) {
  // eslint-disable-next-line prefer-const
  let queryStringParameters = {};

  const tokens = await Auth.currentSession();
  const token = tokens.getIdToken().getJwtToken();

  const { state, selectedDataSource } = arg;

  const fields = [
    ...state.selectedDimensions.map((d) => d.id),
    ...state.selectedMetrics.map((m) => m.id),
  ];

  const dimensionsFilters = [];

  state.dimensionsFilters.forEach((andFilter) => {
    const orFilters = [];

    andFilter.forEach((orFilter) => {
      const orFIlter = {
        ...orFilter,
        fieldName: orFilter.field.id,
      };

      delete orFIlter.field;
      orFilters.push(orFIlter);
    });
    dimensionsFilters.push(orFilters);
  });

  queryStringParameters.dateRange = state.dateRange;
  queryStringParameters.dataSourceId = selectedDataSource.id;
  queryStringParameters.startDate = state.startDate;
  queryStringParameters.endDate = state.endDate;
  queryStringParameters.fields = fields.join(',');
  queryStringParameters.dataProvider = selectedDataSource.dataProvider;
  queryStringParameters.dimensionsFilters = JSON.stringify(dimensionsFilters);

  queryStringParameters.lookerStudioMode = 'true';

  if (state.sortField) {
    queryStringParameters.sort = `${
      state.sortField.sortDirection === 'DESC' ? '-' : ''
    }${state.sortField.id}`;
  }

  if (state.limit) {
    queryStringParameters.limit = state.limit;
  }

  return API.get('DataProviderApi', url, {
    headers: {
      Authorization: token,
    },
    response: true,
    queryStringParameters: {
      ...queryStringParameters,
      outputMode: 'datahub_explorer',
    },
  }).then((res) => res.data);
}

const initialState = {
  dateRange: '',

  selectedMetrics: [],
  selectedDimensions: [],
  startDate: '',
  endDate: '',
  dateRangeError: '',
  dimensionsFilters: [],
  sortField: null,
  limit: '',
};

function DataExplorerDialog({ onClose = () => {} }) {
  const reduxDispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = React.useReducer((oldState, action) => {
    switch (action.type) {
      case 'SET_DATE_RANGE':
        return { ...oldState, dateRange: action.value };

      case 'SET_START_DATE':
        return { ...oldState, startDate: action.value };
      case 'SET_END_DATE':
        return { ...oldState, endDate: action.value };
      case 'SET_SELECTED_METRICS':
        return { ...oldState, selectedMetrics: action.value };
      case 'SET_SORT_FIELD':
        return { ...oldState, sortField: action.value };
      case 'SET_LIMIT':
        return { ...oldState, limit: action.value };
      case 'SET_SELECTED_DIMENSIONS':
        return { ...oldState, selectedDimensions: action.value };
      case 'SET_DATE_RANGE_ERROR':
        return { ...oldState, dateRangeError: action.value };
      case 'SET_CREDENTIAL_ID':
        return { ...oldState, notificationActionCredentialId: action.value };
      case 'SET_DIMENSION_FILTER_PROP':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters = [...oldState.dimensionsFilters];
        newDimensionFilters[action.andFilterIndex][action.orFilterIndex][
          action.property
        ] = action.value;
        return { ...oldState, dimensionsFilters: newDimensionFilters };
      case 'REMOVE_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters2 = [];
        oldState.dimensionsFilters.forEach((dFilter, andIndex) => {
          const dIFs = [];
          dFilter.forEach((dIF, orIndex) => {
            if (
              action.andFilterIndex !== andIndex ||
              action.orFilterIndex !== orIndex
            ) {
              dIFs.push(dIF);
            }
          });
          if (dIFs.length > 0) {
            newDimensionFilters2.push(dIFs);
          }
        });
        return { ...oldState, dimensionsFilters: newDimensionFilters2 };
      case 'ADD_OR_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters3 = [];
        oldState.dimensionsFilters.forEach((dFilter, andIndex) => {
          const dIFs = [];
          dFilter.forEach((dIF) => {
            dIFs.push(dIF);
          });

          if (action.andFilterIndex === andIndex) {
            dIFs.push({
              field: null,
              values: [],
              type: 'INCLUDE',
              operator: 'none',
            });
          }

          newDimensionFilters3.push(dIFs);
        });
        return { ...oldState, dimensionsFilters: newDimensionFilters3 };
      case 'ADD_AND_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters4 = [...oldState.dimensionsFilters];

        newDimensionFilters4.push([
          {
            field: null,
            values: [],
            type: 'INCLUDE',
            operator: 'none',
          },
        ]);

        return { ...oldState, dimensionsFilters: newDimensionFilters4 };
      case 'RESET_STATE':
        return initialState;

      case 'INITIALIZE_FIELDS':
        // eslint-disable-next-line no-case-declarations
        const { fieldIds, sort, dimensionsFiltersObjects, ...otherStateProps } =
          oldState;
        return {
          ...otherStateProps,
          selectedMetrics: action.selectedMetrics,
          selectedDimensions: action.selectedDimensions,
          dimensionsFilters: action.dimensionsFilters,
          sortField: action.sortField,
          startDate: action.startDate,
          endDate: action.endDate,
        };

      default:
        throw new Error();
    }
  }, initialState);

  const [runError, setRunError] = React.useState('');

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource
  );
  const dialogs = useSelector((reduxState) => reduxState.dialogs);
  const dataProviderSettings = useSelector(
    (reduxState) => reduxState.dataProviderSettings
  );

  const configSettings =
    dataProviderSettings.config[selectedDataSource?.dataProvider];

  const {
    trigger,
    isMutating,
    data: connectorData,
    error,
    reset,
  } = useSWRMutation(
    configSettings && `/${configSettings.route}/data`,
    sendRequest
  );

  const openDialog = dialogs.showDataExplorerDialog;

  function closeDialog() {
    reset();
    dispatch({
      type: 'RESET_STATE',
      value: '',
    });
    reduxDispatch({
      type: types.SHOW_DATA_EXPLORER_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  async function runData() {
    let isValid = true;

    if (
      state.dateRange === 'CUSTOM' &&
      (!state.startDate || !state.endDate) &&
      configSettings.dateRangeRequired
    ) {
      isValid = false;
      setRunError(
        'For the Date Range "CUSTOM", the start and end date field is mandatory'
      );
    }

    if (
      state.selectedMetrics.length === 0 &&
      state.selectedDimensions.length === 0
    ) {
      isValid = false;
      setRunError('At least one field (dimension or metric) is required');
    }

    if (!state.dateRange && configSettings.dateRangeRequired) {
      isValid = false;
      setRunError('Please choose a "Date Range"');
    }

    if (isValid) {
      setRunError('');
      trigger({ state, selectedDataSource });
    }
  }

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <>
      <Dialog maxWidth={'lg'} onClose={closeDialog} open={openDialog}>
        <DialogTitle sx={{ textAlign: 'center' }}>Data Explorer</DialogTitle>
        <DialogContent sx={{ minWidth: '600px' }}>
          <QueryComposer
            dataProvider={selectedDataSource?.dataProvider}
            dataSourceId={selectedDataSource?.id}
            dispatch={dispatch}
            state={state}
          />

          <FormHelperText error>{state.dateRangeError}</FormHelperText>

          <Box
            sx={{
              py: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignContent: 'center',
            }}
          >
            <Button
              color="info"
              onClick={() => {
                runData();
              }}
              variant="outlined"
            >
              Explore
            </Button>
          </Box>

          {runError && <Alert severity="error">{runError}</Alert>}

          {isMutating && (
            <Box
              sx={{
                minHeight: '300px',
                mt: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {!isMutating && error && (
            <Alert severity="error">{error?.response?.data?.message}</Alert>
          )}

          {!error && !isMutating && connectorData && (
            <DataGrid
              columns={[
                ...connectorData.columnHeaders.map((columnHeader) => ({
                  field: columnHeader?.name,
                  headerName: columnHeader?.name,
                  flex: 1,
                  minWidth: 110,
                })),
              ]}
              disableSelectionOnClick
              pageSize={5}
              rows={[
                ...connectorData.rows.map((rowArray, i) => {
                  const rowObj = { id: i };
                  rowArray.forEach((rowItem, index) => {
                    rowObj[connectorData.columnHeaders[index].name] = rowItem;
                  });

                  return rowObj;
                }),
              ]}
              rowsPerPageOptions={[5]}
              sx={{ minHeight: '300px', mt: 1 }}
            />
          )}

          {false && (
            <Box sx={{ maxHeight: 300, maxWidth: 400 }}>
              <Typography variant="caption">
                <pre>{JSON.stringify(state, null, 2)}</pre>
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DataExplorerDialog;
