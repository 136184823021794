import { Close } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.action.disabled}`,
  },

  whiteBackground: {
    background: '#fff',
    border: `1px solid ${theme.palette.action.disabled}`,
    color: blue[500],
    fontWeight: 500,
  },
}));

function CredentialLabel({
  name,
  email,
  dataProvider,
  deletable = false,
  deleteAction = async () => {},

  variant = '',
  confirmDeletion = true,
  picture,
  props = {},
}) {
  const classes = useStyles();

  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const containerRef = useRef(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  let onDelelete = null;

  if (deletable === true) {
    onDelelete = async () => {
      if (confirmDeletion === true) {
        setOpenDeleteDialog(true);
      } else {
        await deleteAction();
      }
    };
  }

  return (
    <>
      <Dialog
        maxWidth="sm"
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        open={openDeleteDialog}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Label will be removed from the Data Source
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={async () => {
              await deleteAction();
              setOpenDeleteDialog(false);
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Chip
        {...props}
        className={clsx({
          [classes.outerDiv]: true,
          [classes.whiteBackground]: variant === 'SEARCH_BAR',
        })}
        deleteIcon={<Close fontSize="small" ref={containerRef} />}
        icon={
          <Avatar
            className={clsx({
              [classes.small]: true,
            })}
            src={picture || dataProviderSettings?.config?.[dataProvider]?.image}
          />
        }
        label={
          <>
            <Box className={classes.nameSpan} component="span">
              {name || email}
            </Box>
          </>
        }
        onDelete={onDelelete}
        variant="outlined"
      />
    </>
  );
}

export default CredentialLabel;
