/* eslint-disable no-nested-ternary */

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { Explore, Settings } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  Tab,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { parseBool } from 'adaptivecards';
import { AdaptiveCard } from 'adaptivecards-react';
import { API, Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import JSONPretty from 'react-json-pretty';
import 'react-multi-carousel/lib/styles.css';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import updateDataSourceObject from '../../../api/updateDataSourceObject';
import teams from '../../../config/teams';
import * as types from '../../../constants/ActionTypes';
import QueryComposer from '../QueryComposer';
import EditQuerySettingsDialog from './EditQuerySettingsDialog';

async function sendDataRequest(url, { arg }) {
  // eslint-disable-next-line prefer-const
  let queryStringParameters = {};

  const tokens = await Auth.currentSession();
  const token = tokens.getIdToken().getJwtToken();

  const { state, selectedDataSource } = arg;

  const fields = [
    ...state.selectedDimensions.map((d) => d.id),
    ...state.selectedMetrics.map((m) => m.id),
  ];

  const dimensionsFilters = [];

  state.dimensionsFilters.forEach((andFilter) => {
    const orFilters = [];

    andFilter.forEach((orFilter) => {
      const orFIlter = {
        ...orFilter,
        fieldName: orFilter.field.id,
      };

      delete orFIlter.field;
      orFilters.push(orFIlter);
    });
    dimensionsFilters.push(orFilters);
  });
  queryStringParameters.dateRange = state.dateRange;
  queryStringParameters.dataSourceId = selectedDataSource.id;
  queryStringParameters.startDate = state.startDate;
  queryStringParameters.endDate = state.endDate;
  queryStringParameters.fields = fields.join(',');
  queryStringParameters.dataProvider = selectedDataSource.dataProvider;
  queryStringParameters.dimensionsFilters = JSON.stringify(dimensionsFilters);

  queryStringParameters.lookerStudioMode = 'true';

  if (state.sortField) {
    queryStringParameters.sort = `${
      state.sortField.sortDirection === 'DESC' ? '-' : ''
    }${state.sortField.id}`;
  }

  if (state.limit) {
    queryStringParameters.limit = state.limit;
  }

  queryStringParameters.queryId = `explorer-${state.id}`;

  return API.get('DataProviderApi', url, {
    headers: {
      Authorization: token,
    },
    response: true,
    queryStringParameters: {
      ...queryStringParameters,
      outputMode: 'datahub_explorer',
    },
  }).then((res) => res.data);
}

async function postRequestToDataProviderApi(url, { arg }) {
  const tokens = await Auth.currentSession();
  return API.post('DataProviderApi', url, {
    body: arg,
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  }).then((response) => response.data);
}

async function sendRequest(url, { arg }) {
  const tokens = await Auth.currentSession();

  return API.post('DataProviderApi', url, {
    body: arg,
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  });
}

function CreateQueryDialog({
  open,
  setOpen,
  initialState,
  refreshDataSource,
  setInitialState,
}) {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = React.useReducer((oldState, action) => {
    switch (action.type) {
      case 'SET_DATE_RANGE':
        return { ...oldState, dateRange: action.value };

      case 'SET_START_DATE':
        return { ...oldState, startDate: action.value };
      case 'SET_END_DATE':
        return { ...oldState, endDate: action.value };
      case 'SET_SELECTED_METRICS':
        return { ...oldState, selectedMetrics: action.value };
      case 'SET_SORT_FIELD':
        return { ...oldState, sortField: action.value };
      case 'SET_LIMIT':
        return { ...oldState, limit: action.value };
      case 'SET_SELECTED_DIMENSIONS':
        return { ...oldState, selectedDimensions: action.value };
      case 'SET_DATE_RANGE_ERROR':
        return { ...oldState, dateRangeError: action.value };
      case 'SET_DATA_URL_NAME':
        return { ...oldState, name: action.value };
      case 'SET_CREDENTIAL_ID':
        return { ...oldState, notificationActionCredentialId: action.value };
      case 'SET_SCHEDULE':
        return { ...oldState, schedule: action.value };
      case 'SET_TRIGGER':
        return { ...oldState, trigger: action.value };
      case 'SET_DIMENSION_FILTER_PROP':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters = [...oldState.dimensionsFilters];
        newDimensionFilters[action.andFilterIndex][action.orFilterIndex][
          action.property
        ] = action.value;
        return { ...oldState, dimensionsFilters: newDimensionFilters };
      case 'REMOVE_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters2 = [];
        oldState.dimensionsFilters.forEach((dFilter, andIndex) => {
          const dIFs = [];
          dFilter.forEach((dIF, orIndex) => {
            if (
              action.andFilterIndex !== andIndex ||
              action.orFilterIndex !== orIndex
            ) {
              dIFs.push(dIF);
            }
          });
          if (dIFs.length > 0) {
            newDimensionFilters2.push(dIFs);
          }
        });
        return { ...oldState, dimensionsFilters: newDimensionFilters2 };
      case 'ADD_OR_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters3 = [];
        oldState.dimensionsFilters.forEach((dFilter, andIndex) => {
          const dIFs = [];
          dFilter.forEach((dIF) => {
            dIFs.push(dIF);
          });

          if (action.andFilterIndex === andIndex) {
            dIFs.push({
              field: null,
              values: [],
              type: 'INCLUDE',
              operator: 'none',
            });
          }

          newDimensionFilters3.push(dIFs);
        });
        return { ...oldState, dimensionsFilters: newDimensionFilters3 };
      case 'ADD_AND_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters4 = [...oldState.dimensionsFilters];

        newDimensionFilters4.push([
          {
            field: null,
            values: [],
            type: 'INCLUDE',
            operator: 'none',
          },
        ]);

        return { ...oldState, dimensionsFilters: newDimensionFilters4 };
      case 'RESET_STATE':
        return initialState;

      case 'INITIALIZE_FIELDS':
        // eslint-disable-next-line no-case-declarations
        const { fieldIds, sort, dimensionsFiltersObjects, ...otherStateProps } =
          oldState;
        return {
          ...otherStateProps,
          selectedMetrics: action.selectedMetrics,
          selectedDimensions: action.selectedDimensions,
          dimensionsFilters: action.dimensionsFilters,
          sortField: action.sortField,
          startDate: action.startDate,
          endDate: action.endDate,
        };
      case 'SET_ADDITIONAL_FIELDS':
        return { ...oldState, additionalFields: action.value };

      default:
        throw new Error();
    }
  }, initialState);

  const { enqueueSnackbar } = useSnackbar();

  const [panel, setPanel] = React.useState('1');

  const [runError, setRunError] = React.useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = React.useState(searchParams.get('page') || 'default');

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource
  );

  const dataProviderSettings = useSelector(
    (reduxState) => reduxState.dataProviderSettings
  );

  const configSettings =
    dataProviderSettings.config[selectedDataSource?.dataProvider];

  const {
    trigger: triggerExploreData,
    isMutating: explorerDataIsMutating,
    data: dataProviderData,
    error: dataProviderDataError,
    reset: resetDataProviderData,
  } = useSWRMutation(
    configSettings && `/${configSettings.route}/data`,
    sendDataRequest
  );

  const {
    trigger: triggerPreview,
    isMutating: previewIsMutating,
    data: previewData,
    error: previewError,
    reset: resetPreviewData,
  } = useSWRMutation(
    '/data-providers/adaptive-cards/preview',
    postRequestToDataProviderApi
  );

  const { trigger, isMutating } = useSWRMutation(
    '/data-providers/query',
    sendRequest
  );
  const reduxDispatch = useDispatch();
  useEffect(() => {
    if (initialState) {
      dispatch({
        type: 'RESET_STATE',
      });
    }
  }, [initialState]);

  useEffect(() => {
    setSearchParams((sp) => {
      if (page) {
        sp.set('page', page);
      }
      return sp;
    });
  }, [page]);
  useEffect(() => {
    setSearchParams((q) => {
      if (state.id) {
        q.set('queryId', state.id);
      } else {
        q.delete('queryId');
        q.delete('page');
      }
      return q;
    });
  }, [state]);
  if (!selectedDataSource) {
    return <></>;
  }

  async function runData(type = 'EXPLORE') {
    let isValid = true;

    if (
      state.dateRange === 'CUSTOM' &&
      (!state.startDate || !state.endDate) &&
      configSettings.dateRangeRequired
    ) {
      isValid = false;
      setRunError(
        'For the Date Range "CUSTOM", the start and end date field is mandatory'
      );
    }

    if (
      state.selectedMetrics.length === 0 &&
      state.selectedDimensions.length === 0
    ) {
      isValid = false;
      setRunError('At least one field (dimension or metric) is required');
    }

    if (!state.dateRange && configSettings.dateRangeRequired) {
      isValid = false;
      setRunError('Please choose a "Date Range"');
    }

    if (isValid) {
      setRunError('');
      resetPreviewData();
      resetDataProviderData();
      if (type === 'PREVIEW') {
        triggerPreview({
          ...selectedDataSource,
          ...state,
          fields: [...state.selectedDimensions, ...state.selectedMetrics].map(
            (fieldObj) => fieldObj.id
          ),
          dimensionsFilters: state.dimensionsFilters.map((andFilter) =>
            andFilter.map((orFilter) => ({
              ...orFilter,
              fieldName: orFilter.field.id,
            }))
          ),
          sort: state.sortField
            ? [
                {
                  id: state.sortField.id,
                  sortDirection: state.sortField.sortDirection,
                },
              ]
            : [],
          dataSourceId: selectedDataSource.id,
          dateRangeType: state.dateRange,
          dateRange: {
            startDate: state.startDate,
            endDate: state.endDate,
          },
        });
      } else {
        triggerExploreData({ state, selectedDataSource });
      }
    }
  }

  function closeExplorer() {
    resetDataProviderData();
    resetPreviewData();

    setOpen(false);
  }

  async function createQuery(closeDialog = true) {
    let isValid = true;

    if (
      state.dateRange === 'CUSTOM' &&
      (!state.startDate || !state.endDate) &&
      configSettings.dateRangeRequired
    ) {
      isValid = false;

      enqueueSnackbar(
        'For the Date Range "CUSTOM", the start and end date field is mandatory',
        {
          variant: 'error',
        }
      );
    }

    if (
      state.selectedMetrics.length === 0 &&
      state.selectedDimensions.length === 0
    ) {
      isValid = false;

      enqueueSnackbar('At least one field (dimension or metric) is required', {
        variant: 'error',
      });
    }

    if (!state.dateRange && dataProviderSettings.dateRangeRequired) {
      isValid = false;

      enqueueSnackbar('Please choose a "Date Range"', {
        variant: 'error',
      });
    }

    if (isValid) {
      const queryObj = await trigger({
        id: state.id || '',
        dataSourceId: selectedDataSource.id,
        name: state.name
          ? state.name
          : `Query ${dayjs().format('YYYY-MM-DD HH:mm:ss')}`,
        fields: [...state.selectedDimensions, ...state.selectedMetrics].map(
          (fieldObj) => fieldObj.id
        ),
        sort: state.sortField
          ? [
              {
                id: state.sortField.id,
                sortDirection: state.sortField.sortDirection,
              },
            ]
          : [],
        dateRange: {
          startDate: state.startDate,
          endDate: state.endDate,
        },
        limit: state.limit || '',
        dateRangeType: state.dateRange,
        dimensionsFilters: state.dimensionsFilters.map((andFilter) =>
          andFilter.map((orFilter) => ({
            ...orFilter,
            fieldName: orFilter.field.id,
          }))
        ),
        additionalFields: (state.additionalFields ?? []).map((field) => ({
          name: field.name,
          value: parseBool(field.value) ?? false,
        })),
      });

      const updatedDataSource = await updateDataSourceObject({
        id: selectedDataSource.id,
      });

      batch(() => {
        reduxDispatch({
          type: types.UPDATE_TEAM_DATA_SOURCE,
          dataSource: updatedDataSource,
          teamId: updatedDataSource.team.id,
        });
      });
      if (!state.id) {
        setInitialState(queryObj?.data);

        setPage('settings');
      } else {
        setTimeout(() => {
          if (closeDialog) {
            closeExplorer();
          }
        }, 500);
      }
    }
  }

  return (
    <Dialog
      maxWidth={'lg'}
      onClose={() => {
        setSearchParams((sp) => {
          sp.delete('queryId');
          sp.delete('page');
          return sp;
        });
        closeExplorer();
      }}
      open={open}
    >
      <AppBar
        color="default"
        elevation={0}
        position="static"
        sx={(theme) => ({
          bgcolor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.action.disabled}`,
        })}
      >
        <Toolbar variant="regular">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="100%"
          >
            <Typography align="center" color="inherit" variant="h6">
              Build a Query
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          minWidth: '600px',
          display: page === 'default' ? 'unset' : 'none',
        }}
      >
        <TextField
          fullWidth
          label={'Title of Query'}
          margin="dense"
          onChange={(event) => {
            dispatch({
              type: 'SET_DATA_URL_NAME',
              value: event.target.value,
            });
          }}
          placeholder="Query 1"
          size="small"
          value={state.name}
          variant="outlined"
        />

        <Box sx={{ my: 2 }}>
          <Divider flexItem orientation="horizontal" />
        </Box>
        <QueryComposer
          dataProvider={selectedDataSource?.dataProvider}
          dataSourceId={selectedDataSource?.id}
          dispatch={dispatch}
          state={state}
        />
        <FormHelperText error>{state.dateRangeError}</FormHelperText>
        <Box
          sx={{
            py: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: state.id ? 'space-between' : 'flex-end',
            alignContent: 'center',
          }}
        >
          {state.id && (
            <Button
              color="primary"
              onClick={() => {
                setPage('settings');
              }}
              startIcon={<Settings />}
              variant="outlined"
            >
              Settings
            </Button>
          )}
          <Button
            color="info"
            onClick={() => {
              runData();
            }}
            startIcon={<Explore />}
            variant="outlined"
          >
            Explore
          </Button>
        </Box>

        {runError && <Alert severity="error">{runError}</Alert>}

        {explorerDataIsMutating && (
          <Box
            sx={{
              minHeight: '300px',
              mt: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!explorerDataIsMutating && dataProviderDataError && (
          <Alert severity="error">
            {dataProviderDataError?.response?.data?.message}
          </Alert>
        )}

        {!dataProviderDataError &&
          !explorerDataIsMutating &&
          dataProviderData && (
            <TabContext value={panel}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={(event, newValue) => {
                    setPanel(newValue);
                  }}
                >
                  <Tab label="Preview" value="1" />
                  <Tab label="Raw" value="2" />
                </TabList>
              </Box>
              <TabPanel sx={{ height: '300px', padding: 0 }} value="1">
                <DataGrid
                  columns={[
                    ...dataProviderData.columnHeaders.map((columnHeader) => ({
                      field: columnHeader.name,
                      headerName: columnHeader.name,
                      flex: 1,
                      minWidth: 110,
                    })),
                  ]}
                  disableSelectionOnClick
                  pageSize={5}
                  rows={[
                    ...dataProviderData.rows.map((rowArray, i) => {
                      const rowObj = { id: i };

                      rowArray.forEach((rowItem, index) => {
                        rowObj[dataProviderData.columnHeaders[index].name] =
                          rowItem;
                      });

                      return rowObj;
                    }),
                  ]}
                  rowsPerPageOptions={[5]}
                />
              </TabPanel>
              <TabPanel sx={{ height: '300px', padding: 0 }} value="2">
                <JSONPretty data={dataProviderData}></JSONPretty>
              </TabPanel>
            </TabContext>
          )}

        {previewIsMutating && (
          <Box
            sx={{
              minHeight: '300px',
              mt: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!previewIsMutating && previewError && (
          <Alert severity="error">
            {previewError?.response?.data?.message}
          </Alert>
        )}

        {!previewError && !previewIsMutating && previewData && (
          <Box
            sx={(theme) => ({
              bgcolor: theme.palette.background.default,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              p: 2,
              py: 4,
              border: `1px solid ${theme.palette.action.disabled}`,
            })}
          >
            <AdaptiveCard
              hostConfig={teams}
              payload={previewData}
              style={{ width: '500px', border: '1px solid black' }}
            />
          </Box>
        )}

        {false && (
          <Box sx={{ maxHeight: 300, maxWidth: 400 }}>
            <Typography variant="caption">
              <pre>{JSON.stringify(state, null, 2)}</pre>
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogContent
        sx={{
          minWidth: '600px',
          display: page === 'settings' ? 'unset' : 'none',
        }}
      >
        <EditQuerySettingsDialog
          close={() => {
            setPage('default');
          }}
          initialSettings={state.additionalFields ?? []}
          queryObj={state}
          refreshDataSource={async () => {
            await refreshDataSource();
            // dispatch({
            //   type: 'RESET_STATE',
            //   value: '',
            // });
          }}
          setSettings={(data) => {
            dispatch({
              type: 'SET_ADDITIONAL_FIELDS',
              value: data,
            });
          }}
          teamsPreview={() => {
            setPage('default');
            runData('PREVIEW');
          }}
          update={() => {
            createQuery(false);
          }}
        />
      </DialogContent>

      <DialogActions
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.action.disabled}`,
          py: 2,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              closeExplorer();
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            color="info"
            disabled={page === 'settings'}
            loading={isMutating}
            onClick={createQuery}
            variant="contained"
          >
            {state.id ? 'Update' : 'Create'}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default CreateQueryDialog;
